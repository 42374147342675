<template>
    <div class="l-select"
         @click="clickEvent">
        <Field ref="field"
               :title="title"
               v-model="fieldModel"
               :error="error"
               @keydown.up.native="keyUp"
               @keydown.down.native="keyDown"
               @keydown.enter.native="keyEnter"
               @focus="focusEvent">
            <div v-show="showList"
                 class="l-select__list-wrapper">
                <p v-if="beforeList"
                   class="l-select__before-list">
                    {{ beforeList }}
                </p>
                <ul class="l-select__list">
                    <li v-for="(item, index) in list"
                        class="l-select__item"
                        @click="setItem(item)"
                        @mouseover="activeIndex = index"
                        :class="{
                            'l-select__item-selected': activeKey === getPathItem(item, itemsKey),
                            'l-select__item-hover': activeIndex === index
                        }"
                        :key="getPathItem(item, itemsKey)">
                        <span v-if="beforeItem"
                              class="l-select__before-item">
                            {{ beforeItem(item) }}
                        </span>
                        {{ getPathItem(item, label) }}
                        <span v-if="afterItem"
                              class="l-select__after-item">
                            {{ afterItem(item) }}
                        </span>
                    </li>
                </ul>
                <ul class="l-select__list l-select__list-loading">
                    <li v-for="i in showLoading"
                        class="l-select__item l-select__item-loading"
                        :key="i">
                        <span>Загрузка</span> <span>вариантов</span>
                    </li>
                </ul>
            </div>
        </Field>
    </div>
</template>

<script>
export default {
    name: 'LSelect',
    model: {
        prop: 'model',
        event: 'change'
    },
    props: {
        itemsKey: {
            type: String,
            default: 'id'
        },
        label: {
            type: [String, Array],
            default: 'title'
        },
        title: {
            type: String,
            default: ''
        },
        list: {
            type: Array,
            default: () => ([])
        },
        model: {
            default: ''
        },
        error: {
            type: String,
            default: ''
        },
        beforeList: {
            type: String,
            default: ''
        },
        beforeItem: {
            type: [Function, Boolean],
            default: false
        },
        afterItem: {
            type: [Function, Boolean],
            default: false
        },
        showLoading: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            isFocus: false,
            fieldValue: '',
            activeIndex: 0
        }
    },
    mounted() {
        this.setModel()
        document.addEventListener('click', this.globalEventCheck)
        document.addEventListener('focusin', this.globalEventCheck)
    },
    computed: {
        activeKey() {
            if (!this.model)
                return null

            return this.getPathItem(this.model, this.itemsKey) || null
        },
        field() {
            return this.$refs.field.field
        },
        showList() {
            return this.isFocus && (this.showLoading || this.beforeList || this.list.length > 0)
        },
        fieldModel: {
            get() {
                return this.fieldValue
            },
            set(v) {
                this.fieldValue = v

                if (!this.isFocus)
                    return

                this.$emit('input', v)
            }
        }
    },
    watch: {
        model() {
            this.setModel()
        }
    },
    methods: {
        setModel() {
            const newValue = this.getPathItem(this.model, this.label)

            if (!this.isFocus || (this.isFocus && this.model && newValue !== null))
                this.fieldValue = newValue
        },
        focusEvent() {
            this.isFocus = true
            this.$emit('focus', this.fieldValue)
        },
        setItem(item) {
            this.$emit('listClick')
            this.$emit('change', item)
            this.field.focus()
        },
        clickEvent() {
            this.field.focus()
        },
        globalEventCheck(e) {
            if (this.$el.contains(e.target) || document.activeElement === this.field)
                return

            this.isFocus = false
        },
        getPathItem(item, type) {
            if (!item)
                return null

            if (Array.isArray(type))
                return type.map(i => this.getPathItem(item, i)).filter(i => i).join(', ')

            const path = type.split('.')
            let key = item

            path.forEach(nextKey => key = key[nextKey])
            return key || null
        },
        keyUp(e) {
            e.preventDefault()
            this.activeIndex--

            if (this.activeIndex < 0)
                this.activeIndex = this.list.length - 1
        },
        keyDown(e) {
            e.preventDefault()
            this.activeIndex++

            if (this.activeIndex >= this.list.length)
                this.activeIndex = 0
        },
        keyEnter(e) {
            e.preventDefault()
            if (!(this.list.length && this.list[this.activeIndex]))
                return

            this.setItem(this.list[this.activeIndex])
        }
    },
    beforeDestroy() {
        // @TODO убедиться, что событые удаляется
        document.removeEventListener('focusin', this.globalEventCheck)
        document.removeEventListener('click', this.globalEventCheck)
    }
}
</script>

<style lang="scss">
@import "select";
</style>
